import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/app.css';
import Start from './components/Start';
import Signup from './components/Signup';
import UserAgreement from './components/UserAgreement';
import Login from './components/Login';
import Home from './components/hero';
import Disclaimer from './components/disclaimer';
import Main from './components/main';
import GetPrediction from './components/getPrediction';
import SendEmail from './components/sendEmail';
import AdminPage from './components/AdminPage';
import { UserAuthContextProvider } from './components/UserAuthContext';
import PrivateRoute from './components/PrivateRoute';
import { LanguageProvider } from './components/LanguageContext';
import LanguageSwitcher from './components/LanguageSwitcher';

function App() {
  return (
    <UserAuthContextProvider>
      <LanguageProvider>
        <Router>
          <LanguageSwitcher />
          <Suspense fallback={<div>loading...</div>}>
            <Routes>
              <Route path="/" element={<Start />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/userAgreement" element={<UserAgreement />} />
              <Route path="/login" element={<Login />} />
              {/* admin page route */}
              <Route path="/admin" element={<AdminPage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/start" element={<Home />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/main" element={<Main />} />
                <Route path="/get-prediction" element={<GetPrediction />} />
                <Route path="/email" element={<SendEmail />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </LanguageProvider>
    </UserAuthContextProvider>
  );
}

export default App;
