import React, { useState, useEffect, useCallback } from 'react';
import logo from '../images/aviator1-removebg-preview.png';
// import rotor from '../images/propeller-removebg-preview.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';

const GetPrediction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // activation state
  const [isActivated, setIsActivated] = useState(false);
  const [activationMode, setActivationMode] = useState(null); // 'default' or 'predetermined'
  const [showPopup, setShowPopup] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [activationError, setActivationError] = useState('');

  // prediction animation state
  const [prediction, setPrediction] = useState('1.00x');
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [waitMessage, setWaitMessage] = useState('');

  // server codes & admin value
  const [defaultActivationCode, setDefaultActivationCode] = useState('');
  const [predeterminedActivationCode, setPredeterminedActivationCode] = useState('');
  const [predeterminedValue, setPredeterminedValue] = useState(null);

  const isNativePlatform = Capacitor.isNativePlatform();
  const baseURL = 'https://aviator-l4i7.onrender.com';

  // fetch default activation code
  const fetchDefaultActivationCode = useCallback(async () => {
    try {
      const config = {
        headers: {
          'cache-control': 'no-cache',
          accept: 'application/json',
          'content-type': 'application/json'
        }
      };
      if (isNativePlatform) {
        config.headers['x-requested-with'] = 'xmlhttprequest';
      }
      const res = await axios.get(`${baseURL}/api/activation-code/default`, config);
      console.log('default activation code response:', res.data);
      if (res.data && res.data.activationCode) {
        setDefaultActivationCode(res.data.activationCode);
      } else {
        throw new Error('invalid response format: ' + JSON.stringify(res.data));
      }
    } catch (err) {
      console.error('error fetching default activation code:', err);
    }
  }, [isNativePlatform, baseURL]);

  // fetch predetermined activation code
  const fetchPredeterminedActivationCode = useCallback(async () => {
    try {
      const config = {
        headers: {
          'cache-control': 'no-cache',
          accept: 'application/json',
          'content-type': 'application/json'
        }
      };
      if (isNativePlatform) {
        config.headers['x-requested-with'] = 'xmlhttprequest';
      }
      const res = await axios.get(`${baseURL}/api/activation-code/predetermined`, config);
      console.log('predetermined activation code response:', res.data);
      if (res.data && res.data.activationCode) {
        setPredeterminedActivationCode(res.data.activationCode);
      } else {
        throw new Error('invalid response format: ' + JSON.stringify(res.data));
      }
    } catch (err) {
      console.error('error fetching predetermined activation code:', err);
    }
  }, [isNativePlatform, baseURL]);

  // fetch admin-set predetermined value
  const fetchPredeterminedValue = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/predetermined-number`);
      if (res.data && res.data.predeterminedValue !== undefined) {
        setPredeterminedValue(parseFloat(res.data.predeterminedValue));
      }
    } catch (err) {
      console.error('error fetching predetermined value:', err);
    }
  }, [baseURL]);

  useEffect(() => {
    fetchDefaultActivationCode();
    fetchPredeterminedActivationCode();
    fetchPredeterminedValue();
    // check local storage for persisted activation state
    if (localStorage.getItem('isActivated') === 'true') {
      setIsActivated(true);
      setActivationMode(localStorage.getItem('activationMode'));
    }
  }, [fetchDefaultActivationCode, fetchPredeterminedActivationCode, fetchPredeterminedValue]);

  // poll for predetermined value every 5 seconds
  useEffect(() => {
    const pollingInterval = setInterval(() => {
      fetchPredeterminedValue();
    }, 5000);
    return () => clearInterval(pollingInterval);
  }, [fetchPredeterminedValue]);

  // timer to re-enable the button after 10 seconds
  useEffect(() => {
    let interval;
    if (lastRequestTime) {
      interval = setInterval(() => {
        if (new Date().getTime() - lastRequestTime >= 10000) {
          setIsButtonDisabled(false);
          setWaitMessage('');
          setLastRequestTime(null);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastRequestTime]);

  const handleActivate = () => {
    setActivationError('');
    if (!activationCode.trim()) {
      setActivationError(t('getPrediction.emptyActivationCode'));
      return;
    }
    // check against both codes
    if (activationCode.trim() === defaultActivationCode.trim()) {
      setIsActivated(true);
      setActivationMode('default');
      localStorage.setItem('isActivated', 'true');
      localStorage.setItem('activationMode', 'default');
      setShowPopup(false);
      setActivationCode('');
    } else if (activationCode.trim() === predeterminedActivationCode.trim()) {
      setIsActivated(true);
      setActivationMode('predetermined');
      localStorage.setItem('isActivated', 'true');
      localStorage.setItem('activationMode', 'predetermined');
      setShowPopup(false);
      setActivationCode('');
    } else {
      setActivationError(t('getPrediction.invalidActivationCode'));
    }
  };

  const handleGetPrediction = () => {
    if (!isActivated) {
      setShowPopup(true);
      return;
    }
    if (!lastRequestTime || new Date().getTime() - lastRequestTime >= 10000) {
      if (activationMode === 'default') {
        // default behavior: random target and then redirect after simulated loading
        const newPred = (Math.random() * (6 - 2) + 2).toFixed(2);
        let currentPred = 1.0;
        setPrediction(currentPred.toFixed(2) + 'x');
        const intervalId = setInterval(() => {
          currentPred += 0.1;
          setPrediction(currentPred.toFixed(2) + 'x');
          if (currentPred >= parseFloat(newPred) - 0.30) {
            setTimeout(() => {
              clearInterval(intervalId);
              setPrediction(newPred + 'x');
              navigate('/email');
            }, 3000);
          }
          if (currentPred >= parseFloat(newPred)) {
            clearInterval(intervalId);
            setPrediction(newPred + 'x');
          }
        }, 30);
      } else if (activationMode === 'predetermined') {
        // predetermined behavior: animate to the admin-set value
        if (!predeterminedValue) {
          console.error('predetermined value not loaded');
          return;
        }
        const target = predeterminedValue;
        let current = 1.0;
        setPrediction(current.toFixed(2) + 'x');
        const intervalId = setInterval(() => {
          current += 0.1;
          setPrediction(current.toFixed(2) + 'x');
          if (current >= target) {
            clearInterval(intervalId);
            setPrediction(target.toFixed(2) + 'x');
          }
        }, 30);
      }
      setLastRequestTime(new Date().getTime());
      setIsButtonDisabled(true);
    } else {
      setWaitMessage(t('getPrediction.waitMessage'));
      setTimeout(() => setWaitMessage(''), 3000);
    }
  };

  return (
    <div className="get-prediction-container">
      {showPopup && <div className="overlay"></div>}
      <div className={`predictor ${showPopup ? 'blur' : ''}`}>
        <img src={logo} alt="aviator" />
        <p>{t('getPrediction.aviatorPredictor')} {t('getPrediction.version')}</p>
        <h1>{prediction}</h1>
        <button className="btn" onClick={handleGetPrediction} disabled={isButtonDisabled}>
          {t('getPrediction.getPrediction')}
        </button>
        {waitMessage && <p className="message">{waitMessage}</p>}
      </div>
      {showPopup && (
        <div className="popup">
          <h2>{t('getPrediction.activationRequired')}</h2>
          <p>{t('getPrediction.activationInfo')}</p>
          <input
            type="text"
            value={activationCode}
            onChange={(e) => setActivationCode(e.target.value)}
            placeholder={t('getPrediction.enterActivationCode')}
          />
          {activationError && <p className="error-message">{activationError}</p>}
          <button onClick={handleActivate}>{t('getPrediction.activate')}</button>
        </div>
      )}
    </div>
  );
};

export default GetPrediction;
