import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useTranslation } from 'react-i18next';

const AdminPage = () => {
//   const { t } = useTranslation();
  const [predeterminedValue, setPredeterminedValue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [message, setMessage] = useState('');

  const fetchPredeterminedValue = async () => {
    try {
      const res = await axios.get('https://aviator-l4i7.onrender.com/api/predetermined-number');
      // ('https://aviator-l4i7.onrender.com/api/predetermined-number');
      if (res.data && res.data.predeterminedValue !== undefined) {
        setPredeterminedValue(res.data.predeterminedValue);
        setNewValue(res.data.predeterminedValue);
      }
    } catch (error) {
      console.error('error fetching predetermined value:', error);
    }
  };

  useEffect(() => {
    fetchPredeterminedValue();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://aviator-l4i7.onrender.com/api/update-predetermined', { value: newValue });
      // ('https://aviator-l4i7.onrender.com/api/update-predetermined', { value: newValue });
      if (res.data && res.data.success) {
        setMessage('updated successfully');
        fetchPredeterminedValue();
      }
    } catch (error) {
      setMessage('failed to update');
      console.error('update error:', error);
    }
  };

  return (
    <div className="admin-page-container addy">
      <h1>admin page</h1>
      <p>current predetermined value: {predeterminedValue}</p>
      <form onSubmit={handleUpdate}>
        <label htmlFor="predetermined">new predetermined value:</label>
        <input
          type="number"
          id="predetermined"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          step="0.1"
          required
        />
        <button type="submit" className="btn">update</button>
      </form>
      {message && <p id='message'>{message}</p>}
    </div>
  );
};

export default AdminPage;
